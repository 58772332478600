<template>
  <div class="container">
    <div class="btn-box">
      <button class="down-btn" @click="isIos=true">{{ $t('download.ios') }}</button>
      <button class="down-btn" @click="isIos=false">{{ $t('download.android') }}</button>
    </div>
    <div class="qrcode" :class="isIos ? 'qrcode-ios' : 'qrcode-android'">
      <canvas ref="qrcodeCanvas" :style="canvasStyle"></canvas>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'
export default {
  data() {
    return {
      isIos: false,
      canvasStyle: {}
    }
  },
  methods: {
    generateQRCode(url) {
      const canvas = this.$refs.qrcodeCanvas
      QRCode.toCanvas(canvas, url, (error) => {
        if (error) {
          console.error(error)
        } else {
          this.canvasStyle = {
            width: '14rem',
            height: '14rem'
          }
          this.$forceUpdate()
        }
      })
    }
  },
  mounted() {
    const url = 'https://example.com' // 替换为您想要生成二维码的URL
    this.generateQRCode(url)
  }
}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  .btn-box {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    height: 14rem;
    justify-content: space-between;
    
    .down-btn {
      background: linear-gradient(45deg, #3F63EC 0%, #6384FF 100%);
      width: 18rem;
      height: 5rem;
      border-radius: 2.5rem;
      color: #ffffff;
      font-size: 1.6rem;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      overflow: hidden;
      
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 5px 15px rgba(63, 99, 236, 0.3);
        
        &:before {
          transform: translateX(100%);
        }
      }
      
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          120deg,
          transparent,
          rgba(255, 255, 255, 0.3),
          transparent
        );
        transition: 0.5s;
      }
    }
  }
  
  .qrcode {
    background: white;
    padding: 1rem;
    border-radius: 1rem;
    transition: all 0.3s ease;
    
    &-android, &-ios {
      width: 14rem !important;
      height: 14rem !important;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .qrcode-ios::before,
  .qrcode-android::after {
    content: "";
    position: absolute;
    background-color: #FFF;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 2px;
    left: -1.5rem;
    box-shadow: -2px 0 5px rgba(0,0,0,0.1);
    transition: all 0.3s ease;
  }

  .qrcode-ios::before {
    top: 2.5rem;
    transform: translate(50%, -50%) rotateZ(45deg);
  }

  .qrcode-android::after {
    bottom: 2.5rem;
    transform: translate(50%, 50%) rotateZ(45deg);
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    
    .btn-box {
      width: 100%;
      height: auto;
      
      .down-btn {
        width: 100%;
        margin: 1rem 0;
      }
    }
    
    .qrcode {
      margin-top: 2rem;
    }
  }
}
</style>
