<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { setToken } from '@/utils/auth'
import { mapGetters } from "vuex"
export default {
  name: 'App',
  components: {
  },
  computed: {
    ...mapGetters(['default_language', 'language'])
  },
  data() {
    return {
      corresponding: {
        'zh-CN': 'zhHans',
        'zh-TW': 'zhHanT',
        'en-Us': 'en',
        'vi-VN': 'vi'
      }
    }
  },
  async created() {
    await this.$store.dispatch('webSite/appInit')
    const token = this.getTokenFromURL()
    if (token) {
      setToken(token)
    }
    if (this.default_language && !this.language) {
      this.$i18n.locale = this.corresponding[this.default_language]
      this.$store.dispatch('webSite/setLanguage', this.corresponding[this.default_language])
    }
  },
  methods: {
      getTokenFromURL() {
        const params = new URLSearchParams(this.$route.fullPath);
        return params.get('token');
      },
  }
}
</script>

<style lang="scss">
#app {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #F4F4F4;
}

// 页面过渡动画
.page-transitioning {
  position: relative;
  
  &::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #093267 0%, #031833 100%);
    opacity: 0;
    z-index: 9999;
    pointer-events: none;
    animation: pageTransition 0.3s ease-in-out;
  }
}

@keyframes pageTransition {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}

// 链接点击波纹效果
.ripple {
  position: relative;
  overflow: hidden;
  
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
  }

  &:focus:not(:active)::after {
    animation: ripple 0.8s ease-out;
  }
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  20% {
    transform: scale(25, 25);
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}
</style>
