export default {
  headers: {
    index: '首頁',
    message: '公告消息',
    query: '物流查詢',
    login: '登錄',
    register: '注冊',
    logout: '登出',
    member: '集運中心'
  },
  utils: {
    title: "登出確認",
    confirm: "重新登錄",
    cancel: "取消",
    message: "您的登錄已過期，可以取消返回首頁，也可以重新登錄"
  },
  advantageBox: {
    team: {
      title: '專業團隊',
      subtitle1: '擁有經驗豐富的專業團隊',
      subtitle2: '提供全方面的售後服務'
    },
    qualityControl: {
      title: '質量控制和安全保障',
      subtitle1: '創新控制和安全保障，每壹個環節都進行嚴格的質量控制',
      subtitle2: '確保貨物的安全和完整'
    },
    innovativeTechnology: {
      title: '創新科技應用',
      subtitle1: '采用先進的集運系統提高運營效率，實時跟蹤貨物狀態',
      subtitle2: '確保安全快速達到目的地'
    },
    diverseService: {
      title: '服務多樣化',
      subtitle1: '靈活的運輸選項',
      subtitle2: '可定制的包裝方案'
    }
  },
  immediate: {
    title: "到件即發",
    placeholderTimer: "請選擇時效",
    timer: "時效",
    channel: "渠道",
    placeholderChannel: "請選擇渠道",
    rem: "備註",
    placeholderRem: "如有其他需求或有特別需要注意的, 請留下您的需求備註資訊",
    submit: "提交",
    submitSuccess: "提交成功！",
    addWarning: "添加數量已達上限！"
  },
  exhibitBox: {
    upper: {
      members: '10000+ 會員',
      dailyGoodsWarehousing: '每日貨物入倉 30000+',
      dailyGoodsStorage: '每日貨物入倉 30000+',
      dailyOrders: '每日訂單 10000+',
      dailyShipments: '每日發貨 10000+'
    },
    lower: {
      dailyShipments: '每日發貨 10000+',
      cooperativePickupPoints: '合作自提點 600+'
    }
  },
  toolBox: {
    teach: {
      title: '集運教學',
      subtitle: '圖文及視頻教學指南'
    },
    volume: {
      title: '體積試算',
      subtitle: '貨物體積重量試算'
    },
    shipping: {
      title: '運費試算',
      subtitle: '訂單運費試算'
    },
    chargeIntroduction: {
      title: '收費介紹',
      subtitle: '收費介紹及相關說明'
    }
  },
  introduce: {
    destination: '目的地',
    CalculationModeName: '計算模式',
    CarryModeName: '進位模式',
    CarrierNames: '承運商',
    GoodsTypeNames: '貨物類型',
    TimerNames: '時效',
    range: '範圍',
    firstWeight: '首重',
    unitPrice: '單價',
    surchargeFormula: '附加費(Z表示重量)',
    unit: '單位',
    FirstWeight: "首重重量",
    FirstPrice: "首重价格",
    FirstSurcharge: "首重附加費"
  },
  paymentCard: {
    paymentMethod: '支付方式',
    wechatPay: '微信支付',
    alipayPay: '支付寶支付',
    balancePay: '余額支付',
    paymentAmount: '支付金額',
    rechargeAmount: "充值金額",
    paidInAmount: "實付金額"
  },
  QrCodePay: {
    paymentAmount: '支付金額',
    orderNumber: '訂單號',
    orderDescription: '訂單描述',
    bankDeposit: "開戶銀行",
    openingName: "開戶姓名",
    account: "帳戶",
    prompt: "上傳支付憑證",
    transactionNo: "交易流水號",
    onSubmit: "提交申請",
    placeholderTransactionNo: "請填寫交易流水號",
    placeholderOffline: "請上傳交易憑證",
    successMsg: "提交成功，請等待管理人員審核！",
    rem: "備註"
  },
  agent: {
    title: "成爲代理收益不斷",
    referralCode: "我的推薦碼",
    download: "下載代理二維碼",
    copyLink: "複制代理鏈接",
    WithdrawalMoney: "當前代理收益",
    promotionNumber: "代理會員數量",
    withdrawal: "提現收益到余額",
    member: "會員明細",
    id: "會員編號",
    name: "會員名稱",
    createTime: "注冊時間",
    lastLoginTime: "最近活躍時間",
    withdrawalIncome: "可提現收益",
    allWithdrawal: "全部提現",
    label: "輸入提現金額：",
    submit: "確定提現",
    placeholder: "請輸入提現金額",
    success: "提現成功！",
    recommend: {
      title: "把我們推薦給妳的好友",
      referralCode: "我的推薦碼",
      download: "下載代理二維碼",
      copyLink: "複制代理鏈接",
      promotionNumber: "推廣會員數量",
      member: "會員明細",
      id: "會員編號",
      name: "會員名稱",
      createTime: "注冊時間",
      lastLoginTime: "最近活躍時間",
    }
  },
  flow: {
    title: "消費總金",
    residue: "剩余可用",
    thisWeek: "本周消費",
    today: "今日消費金",
    placeholderTransactionNo: "請輸入流水號",
    placeholderWalletLogType: "消費類型",
    search: "查詢",
    TypeName: "交易類型",
    CreateTime: "交易時間",
    VariableState: "狀態",
    Money: "變動金",
    OldMoney: "變動前",
    OrderNo: "系統單號",
    TransactionNo: "交易流水號",
    Rem: "備注說明",
    Balance: "余額",
    Integral: "積分"
  },
  comment: {
    title: "請寫出您對運輸/送貨服務的總體滿意程度",
    prompt: "寫您的評論",
    placeholder: "請輸入內容",
    veryPoor: "極差",
    disappointed: "失望",
    average: "壹般",
    satisfied: "滿意",
    verySatisfied: "非常滿意",
    callback: "感謝您的評價",
    mainTitle: "爲什麽選擇我們？",
    subTitle: "看其他夥伴怎麽說",
    reply: "商家回複"
  },
  cashier: {
    paymentMethod: '支付方式',
    payNow: '立即支付',
    cancel: '取消',
    confirmBalance: '是否使用余額支付￥({Receivables})嗎？"',
    prompt: "提示",
    confirm: "確認",
    cancelDelete: "已取消支付",
    successPayment: "支付成功",
    warning: "請選擇支付方式"
  },
  footer: {
    domesticConsolidation: '集運流程相關',
    domesticDeliveryService: '香港派送服務',
    domesticStorePickup: '香港門市自取',
    more: '更多...',
    overseasConsolidation: '海外集運',
    overseasDeliveryService: '海外派送服務',
    overseasStorePickup: '海外門市自取',
    noviceTeach: '新手教學',
    frequentlyQuestion: '常見問題',
    freightCalculation: '運費計算',
    earnPoints: '幫助中心',
    cashRoll: '如何獲得優惠券',
    payment: '如何支付',
    companyProfile: '關于我們',
    declarationProtection: '隱私權保護聲明',
    privacyStatement: '應用程序隱私說明',
    disclaimers: '使用條款及免責聲明',
    acceptCarriage: '承運條約',
    contactUs: '聯系我們',
    filings: '@ 2023 戰北 版權所有'
  },
  download: {
    ios: '安裝iOS',
    android: '安裝安卓'
  },
  mergerCard: {
    popover: {
      CarryWeight: "計費重量",
      FirstWeight: "首重",
      FirstPrice: "首重價格",
      FirstWeightSurcharge: "首重附加費",
      LastWeight: "續重",
      LastWeightUnitPrice: "續重單價",
      LastWeightSurcharge: "續重附加費"
    },
    Channel: "渠道",
    placeholderChannel: "請選擇通路",
    VolumeWeight: "體積重量",
    totalVolumeWeight: "總體積重量",
    validateIntegral: "積分超出所擁有的額度",
    yes: "是",
    no: "否",
    preferential: "可優惠",
    servicePrice: "服務費",
    Discount: "折扣率",
    InsuredCommission: "保價手續費",
    AgenServiceFree: "自提點服務費",
    CouponAmount: "優惠券金額",
    IntegralAmount: "積分抵扣",
    AddedServicesFee: "增值服務費用",
    TaxCommission: "包稅手續費",
    CollectionCommission: "代收貨款手續費",
    DestinationCharges: "目的地費用({CalculateFee.CurrencyName})：{CalculateFee.DestinationCharges}",
    AccruedCharge: "應付費用",
    DeclareAccount: "申報數量",
    Size: "尺寸",
    Weight: "重量",
    step1: '包裹信息',
    step2: '訂單配送',
    step3: '訂單信息',
    step4: '下單付款',
    ServiceType: '服務方式',
    placeholderServiceType: '請選擇服務方式',
    Code: '快遞單號',
    Amount: '數量',
    GoodName: '品名',
    GoodsTypeName: '貨物類型',
    Surcharge: '附加費（元）',
    totalCount: '總數量',
    totalWeight: '總重量',
    totalSurcharge: '附加費',
    placeholderGoodsTypeName: '請選擇貨物類型',
    Carrier: '乘運商',
    placeholderCarrier: '請選擇乘運商',
    Timer: '時效',
    placeholderTimer: '請選擇時效',
    AddedService: '增值服務',
    placeholderAddedService: '請選擇增值服務',
    Integral: '積分',
    available: '可用',
    Coupon: '優惠券',
    placeholderCoupon: '請選擇優惠券',
    ClientAddress: '配送至',
    placeholderClientAddress: '請選擇地址',
    CollectionAmount: '代收貨款',
    InsuredAmount: '保價金額',
    IsTax: '是否包稅',
    placeholderIsTax: '請選擇是否包稅',
    DeclareName: '申報人',
    DeclarePhone: '申報手機',
    DeclareAddress: '申報地址',
    DeclareIdCard: '申報統編',
    DeclarePostCode: '申報郵編',
    DeclareEmail: '申報郵箱',
    DeclareTelephone: '申報電話',
    DeclareMoney: '申報總金額',
    DeclarePrice: '申報單價',
    GoodsName: '貨物品名',
    GoodsName_En: '英文品名',
    feeDetail: '費用明細',
    weight: '重量',
    Freight: '運費',
    FreightDetail: '運費計算明細',
    CalculateFeeSurcharge: '包裹附加費',
    CalculateFeeAddressSurcharge: '地址附加費',
    CalculateFeeStorageCharges: '倉儲費',
    CalculateFeeLengthExceed: '超長費',
    packageCount: '包裹數量',
    CalculateFeeWeightExceed: '超重費',
    paymentMethod: '付款方式',
    back: '上壹步',
    next: '下壹步',
    settlement: '結算',
    placeholderGoodsName_En: '請輸入英文品名',
    placeholderGoodsName: '請輸入貨物品名',
    validatePostalCode: '請輸入有效的中國大陸郵政編碼',
    validateLandline: '請輸入有效的中國大陸座機號碼',
    validateEmail: '請輸入有效的郵箱地址',
    validateMobile: '請輸入有效的中國大陸手機號',
    validateCreditCode: '請輸入統編或身份證',
    paySuccess: '支付成功！！！',
    payInfo: '已取消支付！！！，請在訂單列表支付',
    orderSuccess: '下單成功！正在調用支付...'
  },
  route: {
    index: '首頁',
    member: '會員中心',
    personal: '個人中心總覽',
    account: '個人信息設置',
    flow: '消費記錄',
    coupon: '積分和優惠券',
    notify: '通知設置',
    ConsolidatedDomestic: '合並貨物',
    OrderDomestic: '我的訂單',
    AddressDomestic: '收貨地址',
    declare: '貨物申報',
    claimDomestic: '申請預報',
    chargeIntroduce: '收費介紹',
    service: '收費及服務',
    agent: "代理中心",
    recommend: "推薦好友",
    help: 'help',
    Consolidation: '集運須知',
    problem: '常見問題',
    contraband: '運輸違禁品',
    VolumeConversion: '體積計算',
    ShippingCalculation: '運費計算',
    teach: '新手教學',
    lostHandle: '失件處理',
    precautions: '注意事項',
    message: '最新消息',
    logistics: '物流查詢',
    pickupPoint: '自提點供應',
    news: '公告詳情',
    procedureDomestic: '國內集運',
    Login: '登錄',
    Register: '注冊',
    added: '增值服務類型',
    addedList: '增值服務清單',
    shopeeShop: '店鋪管理',
    shopeeOrder: '訂單管理',
    immediate: "到件即發",
    upload: "同行導入",
    lineDomestic: "專線下單",
    OrderLine: "專線訂單",
    line: "專線管理",
    same: "同行相關",
  },
  upload: {
    save: "儲存",
    selectFile: "選擇匯入檔案",
    downloadTemplate: "下載模板檔案",
    delete: "刪除",
    loadingText: "拼命加載中",
    errorMsg: "數據異常消息",
    mainBillCode: "訂單號",
    carrierBillCode: "承運單號",
    subbillNumber: "子單號",
    parcelCode: "包裹單號",
    skuCode: "SKU",
    clientRem: "客戶備註",
    rem: "訂單備註",
    goodsTypeId: "貨物類型",
    timerId: "時效",
    carrierId: "承運商",
    goodsCount: "貨物數量",
    goodsName: "貨物品名",
    goodsNameEn: "英文品名",
    destinationId: "目的地",
    province: "省份",
    city: "城市",
    area: "區域",
    collectionAmount: "代收貨款",
    insuredAmount: "報價金額",
    weight: "實際重量",
    isTax: "是否包稅",
    agentPointId: "自提點",
    idCard: "身份證號碼",
    postNo: "郵政編碼",
    receiveName: "收件人姓名",
    receiveAddress: "收件人地址",
    receivePhoneNo: "收件人手機",
    receiveTel: "收件人電話",
    email: "郵件",
    declareName: "申報人姓名",
    declareAddress: "申報人地址",
    declareTel: "申報人電話",
    declarePhoneNo: "申報人手機",
    declareIdCard: "申報統編",
    declarePrice: "申報單價",
    declareCount: "申報數量",
    declareValue: "申報價值",
    length: "長",
    width: "寬",
    height: "高",
    createTime: "下單時間",
    dialogTitle: "提示",
    warehouseLabel: "所屬倉庫",
    submitButton: "確認提交",
    placeholderWarehouse: "請選擇倉庫",
    templateName: "模板{0}.xlsx",
    downloadError: "下載失敗：{0}",
    downloadSuccess: "下載成功",
    exportSuccess: "匯入成功！！",
    validateError: "存在異常數據，請檢查後提交",
    prompt: "提示",
    message: "此操作將刪除訂單號為（{0}）的表格數據, 是否繼續?",
    confirm: "確認",
    cancel: "取消",
    deleteSuccess: "刪除成功！"
  },
  login: {
    title: '戰北物流',
    subTitle1: '讓運營更簡單',
    subTitle2: '貨著急找站北',
    subTitle3: '3000+企業的合作夥伴',
    subTitle4: '爲妳的滿意刷新我的速度',
    login: '會員登錄',
    account: '用戶名',
    password: '密碼',
    register: '注冊',
    forgetPassword: '忘記密碼',
    submit: '登錄',
    placeholderAccount: '請輸入用戶名',
    placeholderPassword: '請輸入密碼'
  },
  register: {
    title: '戰北物流',
    subTitle1: '讓運營更簡單',
    subTitle2: '貨著急找站北',
    subTitle3: '3000+企業的合作夥伴',
    subTitle4: '爲妳的滿意刷新我的速度',
    item: '成爲{0}會員',
    account: '用戶名',
    password: '密碼',
    phone: '手機號碼',
    aws: '淘寶旺旺號，亞馬遜網店賬號',
    notice: '同意接受本站所發出的所有電郵',
    term: '接受{0}',
    termTitle1: '[服務及條約]',
    termTitle2: '[承運契約條款]',
    termTitle3: '[注意事項]',
    termTitle4: '[失件處理]',
    termUse: '的條款',
    hasAccount: '已有賬號登錄',
    submit: '注冊',
    placeholderAccount: '請輸入用戶名',
    placeholderPassword: '請輸入密碼',
    placeholderPhone: '請輸入手機號',
    mainlandPhone: '請輸入11位的手機號',
    twPhone: '請輸入8位的手機號',
    placeholderTerm: '請閱讀條款並接受！',
    success: '注冊成功！',
    agent: "代理用戶ID",
    recommender: "推薦好友ID",
    email: "郵箱",
    prompt: "服務及條約"
  },
  message: {
    couponsState: {
      upToDate: '最新公告',
      preferential: '優惠活動',
    },
    releaseTime: "發布時間",
    date: "時間",
    content: "標題內容",
    activeTime: '活動時間'
  },
  contraband:{
    title:"運輸及倉儲違禁品",
  },
  losthandle:{
    title:"失件處理",
  },
  problem:{
    domestic:"國內集運",
    foreign:"海外集運",
  },
  shippingcalculation:{
    title:"運費計算",
    destination:"目的地",
    goodtype:"貨物類型",
    volume:"體積（單位）",
    length:"請輸入貨品長度",
    width:"請輸入貨品寬度",
    height:"請輸入貨品高度",
    weight:"請輸入貨品重量",
    submit:"計算",
    result:"運費計算結果",
    warehouseName:"倉庫",
    dialogItems: {
      goodsType: '貨物類型',
      timer: '時效',
      carrier: '承運商',
      fee: '價格(RMB)',
      lengthExceed: '超長費(RMB)',
      carryWeight: '計算重量(KG)'
    },
    rules: {
      selectWarehouse: '請選擇倉庫',
      selectDestination: '請選擇目的地',
      selectGoodType: '請選擇貨物類型',
      enterLength: '請輸入貨品長度',
      enterWidth: '請輸入貨品寬度',
      enterHeight: '請輸入貨品高度',
      enterWeight: '請輸入貨品重量'
    },
  },
  teach:{
    title:"註冊/登記帳護",
    title1: "影片教學",
    title2: "圖文教學",
    loading: "加載中"
  },
  volume:{
    title:"體積計算",
    length:"長度",
    width:"寬度",
    height:"高度",
    fillLength: '請填寫長度',
    fillWidth: '請填寫寬度',
    fillHeight: '請填寫高度',
    lengthplaceholder:"請輸入貨品長度",
    widthplaceholder:"請輸入貨品寬度",
    heightplaceholder:"請輸入貨品高度",
    compute:"計算",
    result:"計算結果",
    notice1: '01: 加收區域或偏遠地區附加費, 請點擊進入各香港配送商了解收費。',
    notice2: '02: 多件集運貨物數量沒有限制。',
    notice3: '03: 寄出的貨品邊長不能過200CM, 總重不能超過300KG。',
    notice4: '04: 如單件貨物的重量超過45KG, 因為搬運困難, 快遞公司會額外收取50-300RMB不等的費用, 單件重量越重, 超重費用越高。',
    notice5: '05: 如單件貨物的邊長超過150CM, 因為派送車輛限制, 快遞公司會額外收取50-300RMB不等的費用, 單件貨物邊長越長, 超長費用越高。',
  },
  footerBox: {
    title1: '探索無限可能',
    title2: '壹鍵下載我們的APP',
    title3: '讓集運更便捷！',
    installIOS: '安裝iOS',
    installAndroid: '安裝安卓',
    features1: '貨物管理 訂單管理  最新消息 即時通訊',
    features2: '地圖搜索自取點',
    features3: '即時物流跟蹤',
    // 其他翻譯...
  },
  noticelayout:{
    title:"集運須知"
  },
  layout: {
    consolidation: {
      tutorial: '新手教學',
      shippingCalculation: '運費試算',
      volumeConversion: '體積換算',
      precautions: '注意事項',
      faq: '常見問題',
      prohibitedItems: '運輸及倉儲違禁品'
    },
    service: {
      aboutUs: '關于我們',
      privacyStatement: '隱私權保護聲明',
      termsAndDisclaimer: '使用條款及免責聲明',
      carriageAgreement: '承運條約',
      contactUs: '聯系我們'
    },
    member: {
      overview: "會員信息總覽",
      accountSettings: "賬戶設置",
      flow: "錢包流水",
      AgencyPromotion: "代理和推廣",
      AgentCenter: "代理中心",
      recommend: "推薦好友",
      myCoupons: "我的優惠券",
      privateMessages: "私信",
      myDomesticWarehouse: "集運管理",
      pickupPoint: "自提點供應",
      domesticWarehouseAddress: "我的收貨地址",
      claimGoods: "包裹預報",
      mergeGoods: "合並貨物",
      orderList: "訂單列表",
      myOverseasWarehouse: "我的海外倉",
      overseasWarehouseAddress: "海外倉地址",
      goodsDeclaration: "貨物申報",
      mergeGoodsOverseas: "合並貨物",
      myOrdersOverseas: "我的訂單",
      notificationSettings: "通知設置",
      addedType: "增值服務類型",
      addedList: "增值服務清單",
      AddedService: "增值服務",
      shopee: '蝦皮相關',
      shopeeShop: '店鋪管理',
      shopeeOrder: '訂單管理'
    }
  },
  service:{
    title:"收費及服務",
    aboutUs: '關于我們',
    privacyStatement: '隱私權保護聲明',
    termsAndDisclaimer: '使用條款及免責聲明',
    carriageAgreement: '承運條約',
    contactUs: '聯系我們'
  },
  rich:{
    title:"幫助中心",

  },
  advantagebox:{
    title:"我們的優勢"
  },
  corebox:{
    title: "服務宗旨",
    title1: "高效且安全",
    notices1: "我們致力於優化物流流程，提供快速響應、嚴格執行各項安全措施，採取高標準的包裝、裝卸作業，並提供全面的貨物保險服務，確保貨物在整個物流鏈中的安全與準時。",
    title2: "質量保證",
    notices2: "堅持以高品質的服務標準為客戶創造價值，利用先進的信息系統和專業的物流團隊，實時跟踪貨物動態，確保物流信息的準確性和透明度。",
    title3: "個性且環保",
    notices3: "針對不同的行業特性和客戶需求，提供專業、環保、防損的個性化包裝方案，提供多種運輸保險選項等增值服務。積極履行社會責任，倡導綠色物流理念，努力降低物流過程中的環境污染和能源消耗。",
    title4: "持續改進",
    notices4: "緊跟行業發展趨勢，不斷引入新的物流技術和管理模式，持續優化服務體系，致力於為客戶提供與時俱進的優質國際物流服務體驗。"
  },
  logistics:{
    search:"查詢",
    records:"曆史記錄",
    clearrecord:"清空記錄",
    placeholder: "請輸入運單號"
  },
  account:{
    passwordChangeSuccess: '密碼修改成功，請重新登錄',
    enterCorrectPhoneNumber: '請輸入正確的手機號',
    copySuccess: '複制成功！',
    copyTextFailed: '複制文本失敗',
    confirmDelete: '此操作將永久刪除該賬號，是否繼續?',
    prompt: '提示',
    confirm: '確定',
    enterPassword: '請輸入密碼',
    deleteAccount: '注銷賬號',
    passwordNotEmpty: '密碼不能爲空',
    deleteRequestSuccess: '注銷申請提交成功！在{time}之前登錄視作取消注銷',
    userChangeSuccess: '修改用戶信息成功！',
    editAvatar: "修改頭像",
    filters: {
      female: '女',
      male: '男',
      secret: '保密',
      unlimited: "不限",
    },
    rules: {
      enterOldPassword: '請輸入原密碼',
      passwordLength: '密碼長度不能低于6位',
      enterNewPassword: '請輸入新密碼',
      passwordMismatch: '兩次輸入的密碼不壹致',
      enterUserName: '請輸入用戶名',
      selectGender: '請選擇性別',
      enterEmail: '請輸入郵箱',
      enterPhoneNumber: '請輸入手機號'
    },
    listData: {
      memberNumber: '會員編號',
      taobaoAccount: '淘寶賬號',
      userName: '用戶昵稱',
      gender: '性別',
      email: '電郵',
      loginPassword: '登錄密碼',
      phoneNumber: '電話',
      avatar: '頭像'
    },
    title:"個人信息設置",
    accountsecurity:"賬號安全",
    password:"修改密碼",
    mailbox:"驗證郵箱",
    modifyemail:"修改郵箱",
    cancellation:"賬號注銷",
    editaccount:"編輯賬戶信息",
    name:"用戶昵稱",
    sex:"性別",
    pleaseselect:"請選擇",
    secrecy:"保密",
    male:"男",
    female:"女",
    unlimited: "不限",
    email:"電郵",
    phone:"電話",
    phonenumber:"手機號碼",
    confirmupdate:"確認修改",
    cancel:"取 消",
    oldpassword:"原密碼",
    newpassword:"新密碼",
    confirmpassword:"確認密碼",
  },
  address:{
    common: {
      prompt: '提示',
      confirm: '確定',
      cancel: '取消',
    },
    AddressSelect: {
      title: "收貨人信息",
      add: "新增收貨地址",
      expand: "展開",
      edit: "編輯",
      delete: "刪除",
      retract: "收起地址"
    },
    pasteAddress: "地址貼上板",
    identifyAddress: "自動識別地址",
    placeholderPasteAddress: "試試貼上收件人姓名、手機號、收貨地址、可快速識別您的收貨信息",
    identify: "識別",
    selectServiceType: '請選擇服務類型',
    confirmDelete: '此操作將永久刪除該地址, 是否繼續?',
    deleteSuccess: '刪除成功!',
    addSuccess: '添加地址成功！',
    editSuccess: '修改地址成功！',
    areas: "區域",
    SetDefaultAddress: '設爲默認',
    defaultSuccess: "設置成功",
    TypeText: "類型",
    editAddress: '編輯地址',
    addAddress: '新增地址',
    selectagent: '自提點',
    selectCountry: '請選擇國家',
    selectProvince: '請選擇省份',
    selectCity: '請選擇城市',
    selectDistrict: '請選擇區縣',
    enterRecipientInfo: '請輸入收件人信息',
    enterContactPhone: '請輸入聯系人電話',
    enterDetailedAddress: '請輸入詳細地址',
    IsDefaultAddress: "默認地址",
    selectAgentPoint: '請選擇自提點地址',
    pickup: "自提",
    dispatch: "派送",
    serviceType: "服務類型",
    title:"我的收貨地址",
    all:"全部地址",
    recipientname:"收件人姓名",
    recipientphone:"聯系電話",
    recipientaddress:"詳細地址",
    destinationname:"目的地",
    operate:"操作",
    edit:"編輯",
    delete:"刪除",
    add:"添加地址",
    disposition:"配置管理",
    role:"角色管理",
    nodistrict:"非工商區",
    district:"工商區",
    destination:"目的地",
    selectdestination:"請選擇目的地",
    province:"省份",
    selectprovince:"省",
    city:"城市",
    selectcity:"市",
    area:"區縣",
    selectarea:"區縣",
    cancel:"取 消",
    confirmupdate:"確認修改",
    Confirmaddition:"確認新增",
    Dispatch: "派送",
    SelfPickup: "自提"
  },
  singlecard:{
    code:"請輸入快遞單號",
    goodsname:"請輸入品名",
    goodstype:"貨物類型",
    account:"貨物數量",
    price:"貨物單價",
    totalprice:"貨物總價",
    clientrem:"備注說明",
    warehouseNumber: '入倉號',
  },
  apply:{
    warehouseNumber: '入倉號',
    enterExpressNumber: '請輸入快遞單號',
    enterGoodsName: '請輸入貨物名稱',
    selectGoodsType: '請選擇貨物類型',
    enterGoodsQuantity: '請輸入貨物數量',
    enterUnitPrice: '請輸入貨物單價',
    enterTotalPrice: '請輸入貨物總價',
    enterInformation: '請填寫信息',
    fillInCompleteInformation: '請將信息填寫完整',
    reportSuccess: '上報成功！',
    noticeTitle: '溫馨提示',
    notice1: '預報運單是當您在購物網站訂單成功後，獲得購物網站確認的物流發貨單號。預報給我們會提高倉儲收發包裹管理，更加高效地處理您的包裹。',
    notice2: '【普貨】無牌，無電，無磁性的電子産品及配件和服裝類..…',
    notice3: '【食品專線】食品渠道不能走純液體，純粉末，電池，馬達，肉類，蛋黃類等物品',
    notice4: '【特A】行車記錄儀、播放器、故事碟、DVD、DV、U盤 MP3/45手表、馬達電池，遙控玩具、電動車玩具、對講機、音箱 仿牌.....隱形眼鏡，美瞳、茶葉化妝品、食品 液體 粉類……',
    notice5: '【特C】藥品平板電腦 手機 .……',
    title:"申請預報",
    individualforecast:"逐個預報",
    batchforecast:"批量預報",
    code:"快遞單號",
    goodsname:"貨物名稱",
    goodstype:"貨物類型",
    account:"貨物數量",
    price:"貨物單價",
    totalprice:"貨物總價",
    clientrem:"備注說明",
    add:"添加",
    remove:"移除",
    submit:"提交"
  },
  added: {
    type: "服務對象",
    name: "服務名稱",
    search: "查詢",
    TypeTxt: "服務類型",
    Price: "服務費用",
    Rem: "服務說明",
    order: "訂單",
    package: "包裹",
    PayState: "付款狀態",
    PayTime: "付款時間",
    IsHandle: "處理狀態",
    HandleTime: "處理時間",
    CreateTime: "創建時間",
    PackageType: "單號類型",
    paid: "已付款",
    unpaid: "未付款",
    handle: "已處理",
    Unprocessed: "未處理",
    placeholderCode: "訂單/包裹編號",
    OrderId: "編號",
    orderCode: "訂單號",
    billCode: "快遞單號"
  },
  goodcard:{
    goodsname:"包裹名稱",
    weight:"重量(KG)",
    code:"快遞單號",
    volumeweight:"體積重(KG)",
    size:"尺寸",
    day: "天",
    remaining: "剩余可免存",
    ProblemTypeName: "問題件類型",
    Rem: "說明",
    HandleStateName: "處理狀態",
    HandleRem: "處理說明",
    HandleTime: "處理時間",
    GoodsTypeName: "貨物類型",
    Account: "數量"
  },
  domestic:{
    showImage: "查看圖片",
    confirmDelete: '此操作將永久刪除包裹（{goodsName}），是否繼續?',
    placeholder: "請輸入快遞單號，多個用空格隔開",
    search: "搜索",
    prompt: '提示',
    confirm: '確定',
    CreateTime: "創建時間",
    deleteSuccess: '刪除成功',
    cancelDelete: '已取消刪除',
    updateSuccess: '修改成功！',
    selectMergePackages: '請先選擇要合並下單的包裹！',
    diffWarehouse: '不能申請不同的倉庫！',
    unstoredGoods: '不能申請未入庫的貨物！',
    UpdateRules: {
      GoodsName: {
        required: '請輸入貨物名稱',
      },
      GoodsTypeName: {
        required: '請選擇貨物類型',
      },
      Account: {
        required: '請輸入貨物數量',
      },
      Price: {
        required: '請輸入貨物價格',
      },
    },
    all:"所有貨物",
    goodsinfo:"貨物信息",
    status:"倉庫狀態",
    arrived:"已到倉",
    waitPayment: "待下單",
    unarrive:"未到倉",
    claim: '待認領',
    forecast: '認領',
    goodClaim: '貨物認領',
    account:"數量(件)",
    warehousename:"倉庫名稱",
    operate:"操作",
    edit:"編輯",
    delete:"刪除",
    selection:"已選",
    addup:"累計(KG)",
    overlength:"超長(M)",
    combine:"合並下單",
    disposition:"配置管理",
    role:"角色管理",
    combinefleight:"合並貨運",
    editinfo:"編輯信息",
    goodsname:"包裹名稱",
    goodstype:"貨物類型",
    selectgoodstype:"請選擇貨物類型",
    goodsaccount:"數量",
    goodsprice:"價格",
    clientrem:"備注",
    confirmupdate:"確認修改",
    cancel:"取 消",
  },
  consolidation: {
    noticeTitle: '注意事項',
  },
  coupon:{
    effectivetime:"生效時間",
    expiredtime:"過期時間",
    use:"立即使用",
    pointscashroll:"積分及現金卷",
    cashroll:"現金卷",
    pointexchange:"積分兌換",
    exchange:"兌換",
    discountcoupon:"優惠券",
    periodOfValidity: "有效期",
    to: "至",
    integral: "積分",
    exchangeSuccess: "兌換成功",
    title: '使用積分兌換優惠券，是否繼續？',
    notice: "至兌換日起{0}天內有效",
    minPrice: "最低使用價格",
    MinWeight: "最低使用重量"
  },
  declare:{
    rules: {
      courier: {
        required: '請輸入快遞單號',
      },
      storehouse: {
        required: '請選擇海外倉庫',
      },
      company: {
        required: '請選擇快遞公司',
      },
      type: {
        required: '請選擇貨物種類',
      },
      classification: {
        required: '請選擇種類分類',
      },
      goodsName: {
        required: '請輸入貨物名稱',
      },
      goodsNum: {
        required: '請輸入貨物數量',
      },
      currency: {
        required: '請選擇種類幣',
      },
      value: {
        required: '請填寫貨物價值',
      },
    },
    title:"海外到香港的貨物 申報",
    code:"快遞單號",
    codeplaceholder:"請輸入快遞單號",
    findcode:"如何找到快遞運單號？",
    storehouse:"海外倉庫",
    company:"快遞公司",
    type:"貨物種類",
    classification:"種類分類",
    goodsname:"貨物名稱",
    goodsNum:"貨物數量",
    currency:"幣種",
    value:"貨物價值",
    reset:"重置",
    submit:"提交",
    placeholderMark: "備注：說明文字描述",
    notice1: '申報貨物時, 請正確選擇海外倉庫, 單號正確而倉庫不正確的話, 狀態會壹直顯示未簽收。',
    notice2: '貨物名稱輸入請使用英文名稱。',
  },
  common: {
    title: "評論",
    submit: "提交",
    imageValidate: "图片大小不能超过5MB！",

  },
  notify:{
    title:"APP推送消息",
    emailmsg:"Email通知",
    unverified:"檢測到您的郵箱未進行驗證",
    latestnotice:"最新優惠通知",
    intelligentservice:"智能服務",
    rem:"貨物到達倉後會自動安排到妳的賬戶內"
  },
  RightBox: {
    title: "回到頂部"
  },
  order:{
    deleteOrder: {
      confirm: '此操作將永久刪除編號爲（{orderId}）的訂單，是否繼續?',
      prompt: '提示',
      confirmText: '確定',
      cancelText: '取消',
      success: '刪除成功',
      cancelDelete: '已取消刪除',
    },
    deliveryOrder: {
      confirm: '此操作將確認收貨編號為（{0}）的訂單，是否繼續?',
      prompt: '提示',
      confirmText: '確定',
      cancelText: '取消',
      success: '收貨成功',
      cancel: '已取消收貨',
    },
    shipmentCode: "發貨單號",
    delivery: "確認收貨",
    trajectory: '物流記錄',
    confirm: '確認',
    emptyTrajectory: "沒有物流記錄",
    CreateTime: "下單時間",
    placeholder: "請輸入訂單號",
    Yes: '是',
    No: "否",
    rate: '評價',
    Weight: "重量(KG)",
    title:"訂單信息",
    mainbillcode:"訂單號",
    id:"編號",
    orderid:"訂單編號",
    carrierbillcode:"承運單號",
    goodsname:"品名",
    export: "匯出",
    exportOrder: "訂單匯出",
    separator: "至",
    startTime: "開始日期",
    endTime: "結束日期",
    receiveinfo:"收件人信息",
    receivename:"收件人名",
    receivephone:"聯系手機",
    receivetelephone:"聯系電話",
    receiveaddress:"收件地址",
    receiveidcard:"統編",
    receivepostcode:"郵編",
    recipientaddress:"收貨地址",
    destination:"目的地",
    selectdestination:"請選擇目的地",
    province:"省份",
    selectprovince:"請選擇省份",
    city:"城市",
    selectcity:"請選擇城市",
    area:"區縣",
    selectarea:"請選擇區縣",
    warehouse:"倉庫",
    goodstype:"貨物類型",
    timer:"時效",
    carrier:"承運商",
    orderrem:"下單備注",
    deliverytitle:"發貨信息",
    ordercode:"快遞單號",
    logistics:"物流公司",
    packagetitle:"訂單包裹信息",
    rem:"備注",
    account:"數量",
    daysinstorage:"到庫天數",
    size:"尺寸",
    weight:"實際重量",
    surcharge:"附加費",
    count:"總件數",
    grossweight:"總重量",
    grosssurcharge:"總附加費",
    storagecharges:"倉租",
    weightcharges:"超重費用",
    lengthcharges:"超長費用",
    fareweight:"總計費重量",
    receivinginfo:"收貨人信息",
    declarationinfo:"申報人信息",
    declarename:"申報人名",
    declarePhone:"聯系手機",
    declaretelephone:"聯系電話",
    declareidcard:"統編",
    declarepostcode:"郵編",
    declareaddress:"申報地址",
    declareaccount:"申報數量",
    declareprice:"申報價格",
    feeinfo:"費用信息",
    collectionamount:"代收貨款",
    taxfee:"包稅費",
    insuredamount:"保價金額",
    couponamount:"優惠券",
    integralamount:"積分抵扣金額",
    destinationcharges:"目的地費用",
    istax:"是否包稅",
    istopay:"是否到付",
    totalfee:"費用合計",
    freight:"運費",
    collectioncommission:"代收貸款手續費",
    insuredcommission:"保價手續費",
    addresssurcharge:"地址附加費",
    addedservicefee:"增值服務費用",
    discount:"享受折扣",
    off:"折",
    accruedcharge:"目的國貨幣({0})",
    paytype:"支付方式",
    payment:"付款",
    paystate:"付款狀態",
    cashier: "收銀台",
    paid:"已付款",
    nonpayment:"未付款",
    createtime:"下單時間",
    printer:"打印",
    domestic:"所有訂單",
    operate:"操作",
    details:"詳情",
    delete:"刪除",
    selection:"已選",
    deleteorder:"刪除訂單",
    disposition:"配置管理",
    role:"角色管理",
    orderdetails:"訂單詳情",
    underway:"待發貨",
    shipped: "已發貨",
    completed:"已完成",
    unPaid: "待付款",
    orderSourceName: "訂單來源"
  },
  recharge: {
    title: "余額充值",
    balance: "余額",
    money: "充值金額",
    confirmMoney: "金額",
    submit: "確認充值",
    warning: "請選擇充值金額！",
    inventory: {
      title: "訂單詳情：余額充值",
      balance: "賬戶余額",
      pon: "虛擬訂單號"
    }
  },
  pickupPoint: {
    Name: "自提點名稱",
    TypeText: "類型",
    DestinationName: "目的地",
    ProvinceName: "省",
    CityName: "城",
    AreaName: "區域",
    Address: "詳細地址",
    Phone: "聯系手機",
    Tel: "聯系電話",
    BusinessHours: "營業時間",
    Postcode: "郵編",
    ServiceCharge: "服務費",
    StorageLife: "存儲期",
    Longitude: "經度",
    Latitude: "緯度",
    Rem: "備注說明",
    AgentStateText: "開發狀態",
    Add: "添加至收貨地址"
  },
  line: {
    title: "專線下單",
    loadingText: "表單提交中...",
    ClientCode: "客戶單號",
    GoodsName: "貨物品名",
    DestinationId: "目的地",
    placeholder: "請選擇",
    GoodsTypeId: "貨物類型",
    CarrierId: "承運商",
    TimerId: "時效",
    WareHouseId: "收貨倉庫",
    RecipientName: "收件人",
    RecipientPhone: "收件手機",
    RecipientEmail: "收件郵箱",
    RecipientPostCode: "收件郵編",
    RecipientAddress: "收件地址",
    Rem: "備註",
    submitForm: "立即下單",
    resetForm: "重置",
    placeholderDestinationId: "請選擇目的地",
    placeholderGoodsTypeId: "請選擇貨物類型",
    placeholderCarrierId: "請選擇承運商",
    placeholderTimerId: "請選擇時效",
    placeholderWareHouseId: "請選擇倉庫",
    placeholderClientCode: "請輸入客戶單號",
    placeholderGoodsName: "請輸入貨物品名",
    placeholderRecipientAddress: "請輸入收件地址",
    placeholderRecipientPhone: "請輸入收件手機",
    placeholderRecipientName: "請輸入收件人",
    submitSuccess: "下單成功！！！",
    length: "長",
    width: "寬",
    height: "高",
    quantity: "數量",
    grossWeight: "毛重",
    tareWeight: "皮重",
    channel: "渠道",
    customsClearance: "是否報關",
    uploadBoxForm: "上傳箱單",
    downloadBoxForm: "下載箱單模板",
    uploadTip: "只能上傳excel文件，且不超過3M",
    addPackage: "添加包裹信息",
    packageWeight: "包裹重量信息",
    grossWeightLabel: "毛重（KG）",
    tareWeightLabel: "皮重（KG）",
    packageSize: "包裹尺寸信息",
    lengthLabel: "長（CM）",
    widthLabel: "寬（CM）",
    heightLabel: "高（CM）",
    packageInfo: "包裹信息",
    packageTip: "貨物信息，不入倉貨物必填",
    confirm: "取 消",
    cancel: "確 定",
    yes: "是",
    no: "否",
  },
  personal:{
    withdrawalMoney: {
      Amount: "提現金額",
      BankName: "開戶銀行",
      OpenName: "開戶姓名",
      BankCard: "銀行卡號",
      submit: "申請提現",
      cancel: "取消",
      title: "餘額提現(帳戶餘額：{0})",
      placeholderAmount: "請填寫提現金額",
      placeholderOpenName: "請填寫開戶姓名",
      placeholderBankName: "請填寫開戶銀行",
      placeholderBankCard: "請填寫銀行卡號",
      withdrawalSuccess: "提現申請已提交！"
    },
    Rem: "備註",
    sign: "簽到",
    signCalendar: "簽到行事曆",
    signSuccess: "簽到成功獎勵積分（{0}）",
    withdrawalMoneyTitle: "提現",
    package: "我的包裹",
    order: "我的訂單",
    notice: "公告",
    confirm: "確 定",
    balance: "余額充值",
    domestic:"國內倉",
    address: "我的收貨地址",
    recharge: "充值",
    arrived:"已到倉",
    recommend: "推薦好友",
    commission: "賺取傭金",
    withdrawal: "可提現傭金",
    unarrive:"未到倉",
    notplaceorder:"待下單",
    placedorder:"待認領",
    domesticaddress:"國內倉專屬地址",
    cargoclaim:"貨物認領",
    combinedcargo:"合並貨物",
    myorder:"我的訂單",
    username:"會員名稱",
    amount: "賬戶余額",
    membernumber:"會員號",
    accountnumber:"賬號",
    deliveryaddress:"收貨地址",
    mycoupon:"積分和優惠券",
    recommendedfriend:"推薦好友",
    integral:"積分",
    coupon:"可用優惠券",
    membercenter:"集運中心",
    ContactPerson: "聯絡人名",
    Phone: "聯系電話",
    PostCode: "郵政編碼",
    DetailedAddress: "收貨地址",
    copyAddress: "壹鍵複制地址",
    title: "集運倉專屬地址",
    pendingPayment: "待付款",
    toBeShipped: "待發貨",
    shipped: "已發貨",
    completed: "已完成",
  },
  procedure:{
    demonstrate: {
      step1: {
        title: '注冊賬號成爲會員',
        subTitle: ''
      },
      step2: {
        title: '獲得並選定集運倉地址作爲購物平台的收貨地址',
        subTitle: ''
      },
      step3: {
        title: '將購買好的貨物認領到本網站平台',
        subTitle: ''
      },
      step4: {
        title: '貨物到倉庫後並申請發貨',
        subTitle: ''
      },
      step5: {
        title: '貨物到達目的地，自提或派送上門',
        subTitle: ''
      }
    },
    servers: {
      step1: {
        title: '貨物自提',
        introduces: [
          '超300+自提合作網點,選擇最適合妳的壹家提貨!\n' +
          '服務優點:節省成本、靈活時間安排、控制交貨流程',
          '·  將貨物發至指定的物流點。',
          '· 物流公司將貨物送達後通知收貨人。',
          '· 收貨人攜帶有效身份證明以及取件碼或快遞單號等相關信息，前往指定地點辦理提貨手續。'
        ]
      },
      step2: {
        title: '派送到門',
        introduces: [
          '快遞員在約定的時間範圍內送達包裹',
          '並與收件人完成簽收手續'
        ]
      },
      step3: {
        title: '物流軌迹實時跟蹤',
        introduces: [
          '當訂單軌迹狀態發生變化時，系統會實時更新並同步至我們的查詢平台',
          '會員中心或手機APP上輸入唯壹的物流單號，即可查看詳細的訂單軌迹',
          '全天候24小時可用，無論何時何地，您都可以隨時查詢了解貨物的位置和狀態'
        ]
      },
      // 其他翻譯...
    },
    process:"集運流程",
    learnmore:"了解更多",
    checkMore: "查看倉庫地址",
    warehouseIntroduction: '集運倉庫',
    warehouseLocation: '擁有總面積超過10000平方米的現代化倉庫,分區明確，適應不同類型商品存儲需求,地理位置位于交通樞紐地帶，緊鄰港口、機場或主要公路網絡，確保貨物能夠快速、便捷地進行集散和轉運，大大縮短運輸時間，降低物流成本',
    warehouseFunction: '采用先進的自動化立體倉庫技術，實現高效精准的入庫、出庫及庫存管,24小時全天候視頻監控、消防設施完備，並配備專業的保安團隊，確保貨物安全無虞',
    learnMore: '了解更多',
    weightCalculation: '計費重量',
    weightCalculationNote1: '運費計算中，收費重量基于兩個要素：實際毛重和體積重量。實際毛重是指貨物實體的重量；體積重量則是考慮包裹雖輕但體積大時的壹種等效重量計算方式。',
    weightCalculationNote2: '實際毛重：直接稱量貨物得出。',
    weightCalculationNote3: '體積重量：通過公式計算（長x寬x高 ÷ 計費因子），並根據運輸方式有不同的計費因子標准。',
    weightCalculationNote4: '運費將以實際毛重與體積重量兩者中較大的數值作爲計費基礎。此外，對于部分特殊貨物或服務，可能還會有最低計費重量、超大超重附加費等額外規定。如有疑問或需要詳細報價，請隨時聯系我們的客服團隊。',
    declarationTitle: '貨物申報',
    declarationNote1: '確保所有貨物信息如品名、規格、數量、價值等與實際貨物完全壹致，並提供完整且真實的商業發票、裝箱單和相關證書,以便貨物進出口環節順暢進行申報，有效降低通關風險',
    shippingPackage: '增值服務',
    packageNote1: '如果妳的貨物因太泡而需要折除包裝，又或外包裝屬於多角形狀，無法擠進國際集運包裝箱，那麼我們提供免費的重新包裝服務。重新包裝後體積會相應減少，但不是絕對。',
  }
}
