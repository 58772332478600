<template>
  <el-dropdown trigger="click" class="international" @command="handleSetLanguage">
    <div>
      <el-image class="language-img" :src="require('@/static/images/layout/language.png')" fit="cover"></el-image>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item :disabled="language==='zhHans'" command="zhHans">
        中文简体
      </el-dropdown-item>
      <el-dropdown-item :disabled="language==='en'" command="en">
        English
      </el-dropdown-item>
      <el-dropdown-item :disabled="language==='zhHanT'" command="zhHanT">
        中文繁体
      </el-dropdown-item>
      <el-dropdown-item :disabled="language==='vi'" command="vi">
        Tiếng Việt
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  computed: {
    language() {
      return this.$store.getters.language
    }
  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.dispatch('webSite/setLanguage', lang)
    }
  }
}
</script>

<style lang="scss" scoped>
.international {
  padding: 0.5rem;
  border-radius: 6px;
  transition: all 0.3s ease;
  
  &:hover {
    background: rgba(64, 158, 255, 0.1);
  }
}

.language-img {
  width: 2.4rem;
  height: 2.4rem;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.1);
  }
}
</style>
