<template>
  <div class="container" v-show="isShow">
    <div class="upper">
      <div class="box">
        <div class="box-item">
          <download></download>
        </div>
        <div class="box-item">
          <div class="title-item" @click="jumpPage('/procedure-domestic', true)">{{ $t('footer.domesticConsolidation') }}</div>
          <div class="title-item">{{ $t('footer.domesticDeliveryService') }}</div>
          <div class="title-item">{{ $t('footer.domesticStorePickup') }}</div>
          <div class="title-item" @click="jumpPage('/service/about-Us', true)">{{ $t('footer.more') }}</div>
        </div>
        <div class="box-item">
          <div class="title-item" @click="jumpPage('/consolidation/teach', true)">{{ $t('footer.noviceTeach') }}</div>
          <div class="title-item" @click="jumpPage('/consolidation/problem', true)">{{ $t('footer.frequentlyQuestion') }}</div>
          <div class="title-item" @click="jumpPage('/consolidation/shipping-calculation', true)">{{ $t('footer.freightCalculation') }}</div>
          <div class="title-item" @click="jumpPage('/consolidation/problem', true)">{{ $t('footer.more') }}</div>
        </div>
        <div class="box-item">
          <div class="title-item" @click="jumpPage(`/help/rich?id=${richId}`, true)">{{ $t('footer.earnPoints') }}</div>
<!--          <div class="title-item" @click="jumpPage('/help/rich?id=14', true)">{{ $t('footer.cashRoll') }}</div>-->
<!--          <div class="title-item" @click="jumpPage('/help/rich?id=13', true)">{{ $t('footer.payment') }}</div>-->
          <div class="title-item" @click="jumpPage(`/help/rich?id=${richId}`, true)">{{ $t('footer.more') }}</div>
        </div>
      </div>
    </div>
    <div class="lower">
      <div class="lower-item">
        <div class="title-item" @click="jumpPage('/service/about-Us', true)">{{ $t('footer.companyProfile') }}</div>
        <div class="title-item" @click="jumpPage('/service/privacy', true)">{{ $t('footer.declarationProtection') }}</div>
        <div class="title-item" @click="jumpPage('/service/term', true)">{{ $t('footer.disclaimers') }}</div>
        <div class="title-item" @click="jumpPage('/service/carriage-treaty', true)">{{ $t('footer.acceptCarriage') }}</div>
        <div class="title-item" @click="jumpPage('/service/contact-Us', true)">{{ $t('footer.contactUs') }}</div>
        <div class="title-item">{{ $t('footer.filings') }}</div>
        <div class="title-item" @click="toSearch">{{ recordNo }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Download from './Download.vue'
import { mapGetters } from "vuex"

export default {
  components: { Download },
  data() {
    return {
      richId: undefined,
      isNavigating: false
    }
  },
  computed: {
    isShow() {
      return this.$route.path.indexOf('member') === -1
    },
    ...mapGetters(['recordNo'])
  },
  mounted() {
    this.$store.dispatch('webSite/getShopMatters', 2).then(response => {
      this.richId = response[0].Id
    })
  },
  methods: {
    async jumpPage(path, scrollTo = false) {
      if (this.isNavigating) return;
      this.isNavigating = true;

      const target = event.currentTarget;
      target.style.transform = 'scale(0.95)';
      
      if (scrollTo) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }

      try {
        await this.$router.push({ 
          path,
          replace: this.$route.path === path
        });
      } catch (err) {
        if (err.name !== 'NavigationDuplicated') {
          console.error(err);
        }
      }

      target.style.transform = 'scale(1)';
      this.isNavigating = false;
    },
    
    toSearch() {
      window.open('https://beian.miit.gov.cn/', '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  
  .upper {
    width: 100%;
    min-height: 24rem;
    background: linear-gradient(135deg, #093267 0%, #031833 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    
    .box {
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
      color: white;
      font-size: 1.2rem;
      font-family: PingFang SC;
      max-width: 1200px;
      width: 100%;
      
      .box-item {
        margin: 0 2rem;
        
        .title-item {
          padding: 0.8rem 0;
          cursor: pointer;
          position: relative;
          transition: all 0.3s ease;
          
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 2px;
            background: #fff;
            transition: width 0.3s ease;
          }
          
          &:hover {
            color: #fff;
            transform: translateX(5px);
            
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }
  
  .lower {
    width: 100%;
    min-height: 5rem;
    background-color: #031833;
    position: relative;
    
    .lower-item {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 50%);
      bottom: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 1.2rem;
      font-weight: 500;
      flex-wrap: wrap;
      
      .title-item {
        padding: 0.5rem 1.5rem;
        white-space: nowrap;
        cursor: pointer;
        position: relative;
        transition: all 0.3s ease;
        
        &:not(:last-child):after {
          content: '|';
          position: absolute;
          right: 0;
          opacity: 0.5;
        }
        
        &:hover {
          color: #3F63EC;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .upper .box {
      flex-direction: column;
      align-items: center;
      
      .box-item {
        margin: 2rem 0;
        text-align: center;
      }
    }
    
    .lower .lower-item {
      position: relative;
      transform: none;
      padding: 1rem;
    }
  }
}
</style>
