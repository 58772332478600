import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home/index.vue';
import Login from "@/views/Home/Login.vue";
import Register from "@/views/Home/Register.vue";
import store from '@/store/index.js';
import getPageTitle from "@/utils/get-page-title";

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            redirect: '/index',
            component: Home,
            children: [
                {
                    path: 'index',
                    name: 'index',
                    component: () => import(/* webpackChunkName: "home" */ '../views/layout/index.vue'),
                    meta: {
                        title: 'index',
                        internal: false
                    }
                },
                {
                    path: 'member',
                    name: 'member',
                    redirect: '/member/personal',
                    component: () => import(/* webpackChunkName: "home" */ '../views/member/layout.vue'),
                    meta: {
                        title: 'member',
                        auth: true,
                        internal: true
                    },
                    children: [
                        {
                            path: 'same/upload',
                            name: 'Upload',
                            component: () => import('../views/member/same/Upload.vue'),
                            meta: {
                                title: 'upload',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'cod/category',
                            name: 'category',
                            component: () => import('../views/member/cod/category.vue'),
                            meta: {
                                title: 'upload',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'cod/sku',
                            name: 'category',
                            component: () => import('../views/member/cod/sku.vue'),
                            meta: {
                                title: 'upload',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'cod/warehouse',
                            name: 'warehouse',
                            component: () => import('../views/member/cod/warehouse.vue'),
                            meta: {
                                title: 'upload',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'cod/inventory',
                            name: 'inventory',
                            component: () => import('../views/member/cod/inventory.vue'),
                            meta: {
                                title: 'upload',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'cod/order',
                            name: 'codOrder',
                            component: () => import('../views/member/cod/order.vue'),
                            meta: {
                                title: 'order',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'cod/pendShipOrder',
                            name: 'codPendShipOrder',
                            component: () => import('../views/member/cod/pendShipOrder.vue'),
                            meta: {
                                title: 'order',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'cod/ShippedOrder',
                            name: 'codShippedOrder',
                            component: () => import('../views/member/cod/ShippedOrder.vue'),
                            meta: {
                                title: 'order',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'cod/upload',
                            name: 'codUpload',
                            component: () => import('../views/member/cod/Upload.vue'),
                            meta: {
                                title: 'order',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'personal',
                            name: 'personal',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/personal/index.vue'),
                            meta: {
                                title: 'personal',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'agent',
                            name: 'Agent',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/agent/index.vue'),
                            meta: {
                                title: 'agent',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'recommend',
                            name: 'Recommend',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/agent/recommend.vue'),
                            meta: {
                                title: 'recommend',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'account',
                            name: 'account',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/account/index.vue'),
                            meta: {
                                title: 'account',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'coupon',
                            name: 'coupon',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/coupon/index.vue'),
                            meta: {
                                title: 'coupon',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'notify',
                            name: 'notify',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/notify/index.vue'),
                            meta: {
                                title: 'notify',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'added',
                            name: 'Added',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/added/index.vue'),
                            meta: {
                                title: 'added',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'added-list',
                            name: 'AddedList',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/added/list.vue'),
                            meta: {
                                title: 'AddedList',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'consolidated-domestic',
                            name: 'ConsolidatedDomestic',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/consolidated/domestic/index.vue'),
                            meta: {
                                title: 'ConsolidatedDomestic',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'order-domestic',
                            name: 'OrderDomestic',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/order/domestic/index.vue'),
                            meta: {
                                title: 'OrderDomestic',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'order-line',
                            name: 'OrderLine',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/order/line/index.vue'),
                            meta: {
                                title: 'OrderLine',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'address-domestic',
                            name: 'AddressDomestic',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/address/domestic/index.vue'),
                            meta: {
                                title: 'AddressDomestic',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'declare',
                            name: 'declare',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/declare/index.vue'),
                            meta: {
                                title: 'declare',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'flow',
                            name: 'flow',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/flow/index.vue'),
                            meta: {
                                title: 'flow',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'immediate',
                            name: 'immediate',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/immediate/index.vue'),
                            meta: {
                                title: 'immediate',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'pickup-point',
                            name: 'pickupPoint',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/pickupPoint/index.vue'),
                            meta: {
                                title: 'pickupPoint',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'claim-domestic',
                            name: 'claim-domestic',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/apply/index.vue'),
                            meta: {
                                title: 'claimDomestic',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'line',
                            name: 'line',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/apply/line.vue'),
                            meta: {
                                title: 'lineDomestic',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'shopee/shop',
                            name: 'shopeeShop',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/shopee/shop.vue'),
                            meta: {
                                title: 'shopeeShop',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'shopee/order',
                            name: 'shopeeOrder',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/shopee/order.vue'),
                            meta: {
                                title: 'shopeeOrder',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'shopee/pendShipOrder',
                            name: 'shopeePendShipOrder',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/shopee/pendShipOrder.vue'),
                            meta: {
                                title: 'shopeePendShipOrder',
                                internal: true,
                                auth: true,
                            }
                        },
                        {
                            path: 'shopee/ShippedOrder',
                            name: 'shopeeShippedOrder',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/shopee/ShippedOrder.vue'),
                            meta: {
                                title: 'shopeeShippedOrder',
                                internal: true,
                                auth: true,
                            }
                        }
                    ]
                },
                {
                    path: 'charge-introduce',
                    name: 'chargeIntroduce',
                    component: () => import('../views/charge/introduce.vue'),
                    meta: {
                        title: 'chargeIntroduce',
                        auth: false,
                        internal: true
                    }
                },
                {
                    path: 'service',
                    name: 'service',
                    component: () => import('../views/Fee-Service/layout.vue'),
                    meta: {
                        title: 'service',
                        auth: true,
                        internal: true
                    },
                    children: [
                        {
                            path: ':name',
                            name: 'dispatch',
                            component: () => import(/* webpackChunkName: "home" */ '../views/Fee-Service/service/index.vue'),
                            meta: {
                                title: 'service',
                                auth: true,
                                internal: true
                            }
                        },
                    ]
                },
                {
                    path: 'help',
                    name: 'help',
                    component: () => import('../views/Help/layout.vue'),
                    meta: {
                        title: 'help',
                        auth: true,
                        internal: true
                    },
                    children: [
                        {
                            path: 'rich',
                            name: 'rich',
                            component: () => import(/* webpackChunkName: "home" */ '../views/Help/rich/index.vue'),
                            meta: {
                                title: 'help',
                                auth: true,
                                internal: true
                            }
                        },
                    ]
                },
                {
                    path: 'consolidation',
                    name: 'Consolidation',
                    redirect: '/consolidation/problem',
                    component: () => import(/* webpackChunkName: "home" */ '../views/Consolidation-Notice/layout.vue'),
                    meta: {
                        title: 'Consolidation',
                        auth: true,
                        internal: true
                    },
                    children: [
                        {
                            path: 'problem',
                            name: 'problem',
                            component: () => import(/* webpackChunkName: "home" */ '../views/Consolidation-Notice/problem/index.vue'),
                            meta: {
                                title: 'problem',
                                auth: true,
                                internal: true
                            }
                        },
                        {
                            path: 'contraband',
                            name: 'contraband',
                            component: () => import(/* webpackChunkName: "home" */ '../views/Consolidation-Notice/contraband/index.vue'),
                            meta: {
                                title: 'contraband',
                                auth: true,
                                internal: true
                            }
                        },
                        {
                            path: 'volume-conversion',
                            name: 'VolumeConversion',
                            component: () => import(/* webpackChunkName: "home" */ '../views/Consolidation-Notice/volume/index.vue'),
                            meta: {
                                title: 'VolumeConversion',
                                auth: false,
                                internal: true
                            }
                        },
                        {
                            path: 'shipping-calculation',
                            name: 'ShippingCalculation',
                            component: () => import(/* webpackChunkName: "home" */ '../views/Consolidation-Notice/shipping-calculation/index.vue'),
                            meta: {
                                title: 'ShippingCalculation',
                                auth: false,
                                internal: true
                            }
                        },
                        {
                            path: 'teach',
                            name: 'teach',
                            component: () => import(/* webpackChunkName: "home" */ '../views/Consolidation-Notice/teach/index.vue'),
                            meta: {
                                title: 'teach',
                                auth: false,
                                internal: true
                            }
                        },
                        {
                            path: 'lost-handle',
                            name: 'lost-handle',
                            component: () => import(/* webpackChunkName: "home" */ '../views/Consolidation-Notice/lost-handle/index.vue'),
                            meta: {
                                title: 'lostHandle',
                                auth: true,
                                internal: true
                            }
                        },
                        {
                            path: 'precautions',
                            name: 'precautions',
                            component: () => import(/* webpackChunkName: "home" */ '../views/Consolidation-Notice/notice/index.vue'),
                            meta: {
                                title: 'precautions',
                                auth: true,
                                internal: true
                            }
                        }
                    ]
                },
                {
                    path: 'message',
                    name: 'message',
                    component: () => import(/* webpackChunkName: "home" */ '../views/message/index.vue'),
                    meta: {
                        title: 'message',
                        auth: true,
                        internal: true
                    },
                },
                {
                    path: 'logistics-query',
                    name: 'logistics',
                    component: () => import(/* webpackChunkName: "home" */ '../views/Logistics-Query/index.vue'),
                    meta: {
                        title: 'logistics',
                        auth: false,
                        internal: false
                    },
                },
                {
                    path: 'news-detail',
                    name: 'news',
                    component: () => import(/* webpackChunkName: "home" */ '../views/message/news-detail/index.vue'),
                    meta: {
                        title: 'news',
                        auth: true,
                        internal: true
                    }
                },
                {
                    path: 'procedure-domestic',
                    name: 'procedure-domestic',
                    component: () => import(/* webpackChunkName: "home" */ '../views/procedure/domestic/index.vue'),
                    meta: {
                        title: 'procedureDomestic',
                        internal: false
                    }
                }
            ]
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                title: 'Login'
            }
        },
        {
            path: '/outside/trajectory',
            name: 'Trajectory',
            component: () => import(/* webpackChunkName: "home" */ '../views/Logistics-Query/outside.vue'),
            meta: {
                title: "trajectory"
            }
        },
        {
            path: '/register',
            name: 'Register',
            component: Register,
            meta: {
                title: 'Register'
            }
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    }
});

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
    const token = to.query.token || '';
    if (token) {
        store.dispatch('user/backendJump', token).then(() => {
            const newRoute = { ...to, query: {} };
            next(newRoute);
        })
    }
    document.title = getPageTitle(to.meta.title);
    store.dispatch('webSite/changePath', to.path);
    store.dispatch('webSite/headerRecast', to.meta.internal || false)
    if (to.meta.auth && !store.getters.token) {
        store.dispatch('user/redirectLogin');
    } else if (to.name === 'Login' && store.getters.isAuthenticated) {
        document.title = getPageTitle('index');
        next({name: 'index'});
    } else {
        document.body.classList.add('page-transitioning');
        next();
    }
});

router.afterEach(() => {
    setTimeout(() => {
        document.body.classList.remove('page-transitioning');
    }, 300);
});

export default router;
