<template>
  <div class="tooltip-container">
    <span class="text" :style="{ color: internal ? 'white' : 'black' }">
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 95 114"
        class="svgIcon"
    >
      <rect :style="{ fill : internal ? 'white' : 'black'}" rx="28.5" height="57" width="57" x="19"></rect>
      <path
          :style="{ fill : internal ? 'white' : 'black'}"
          d="M0 109.5C0 83.2665 21.2665 62 47.5 62V62C73.7335 62 95 83.2665 95 109.5V114H0V109.5Z"
      ></path>
    </svg>
    {{ userInfo.Name }}</span
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  computed: {
    ...mapGetters(['userInfo', 'internal'])
  }
}

</script>

<style lang="scss" scoped>
.tooltip-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s;
  padding: 0.8rem 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  
  &:hover {
    background-color: rgba(64, 158, 255, 0.1);
  }
}

.text {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  
  .svgIcon {
    width: 1.8rem;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  &:hover .svgIcon {
    transform: scale(1.1);
  }
}
</style>